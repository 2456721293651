var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { FlagComponent } from '../../Flag';
import { theme } from '../../../theme';
import { TextOverflow } from '../../TextOverflow';
import { Link } from 'react-router-dom';
import { OrderInProgressStatus, OrderLocation, OrderQuoteStatusState, OrderStatus, } from '../../../models';
import { makeStyles } from '@material-ui/styles';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { dateViewFormat } from '../../DateView';
var useItemStyles = makeStyles(function (theme) { return ({
    root: {
        cursor: 'pointer',
        paddingTop: theme.spacing(1),
        borderBottom: "1px solid transparent",
        '&:hover': {
            borderBottom: "1px solid " + theme.palette.success.light,
        },
    },
}); });
var MatchText = function (_a) {
    var text = _a.text, match = _a.match, parentStyle = _a.parentStyle, props = __rest(_a, ["text", "match", "parentStyle"]);
    var matches = [];
    try {
        matches = text.split(new RegExp(match, 'gi'));
        if (matches.length > 1)
            matches.splice(1, 0, text.match(new RegExp(match, 'gi'))[0]);
    }
    catch (e) {
        console.log(e);
        matches = [];
    }
    return (React.createElement("span", { style: __assign({ display: 'flex' }, parentStyle) }, matches.map(function (e) {
        var styles = __assign(__assign(__assign({}, props.style), { width: 'initial', display: 'block' }), (e.toLowerCase() === match.toLowerCase() && { fontWeight: 900, color: theme.palette.success.light }));
        return (React.createElement(TextOverflow, __assign({ key: e }, props, { style: styles }), e));
    })));
};
var EmptyIcon = function () {
    return React.createElement(SearchIcon, { style: { minWidth: 32 } });
};
export var SearchOrderView = function (_a) {
    var order = _a.order, search = _a.search, props = __rest(_a, ["order", "search"]);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var langMenu = useTranslation(KEYS.MENU);
    var Flag = order.transportations[0] &&
        order.transportations[0].routes[0] &&
        order.transportations[0].routes[0].origin &&
        order.transportations[0].routes[0].origin.address &&
        order.transportations[0].routes[0].origin.address.country;
    var classes = useItemStyles();
    var statusText = [
        "(" + (order.status === OrderStatus.Finished
            ? langMenu.t('menu_sidebar_archive_title')
            : order.status === OrderStatus.ApprovedQuote
                ? langMenu.t('menu_sidebar_in_progress_title')
                : !![OrderStatus.AcceptedQuote, OrderStatus.WaitingQuotes, OrderStatus.HasQuotes].find(function (status) { return status === order.status; })
                    ? langMenu.t('menu_sidebar_open_orders_title')
                    : langOrderForm.t("create_order_" + order.status + "_as_template")) + ")",
    ].filter(function (e) { return e; });
    var content = (React.createElement(Grid, __assign({ container: true, alignItems: 'flex-end', justify: 'space-between', className: classes.root }, props),
        React.createElement(Grid, { container: true, item: true, xs: 1, alignItems: 'center', justify: "center", style: { height: '100%' } }, (Flag && React.createElement(FlagComponent, { country: Flag })) || React.createElement(EmptyIcon, null)),
        React.createElement(Grid, { container: true, item: true, xs: 11 },
            React.createElement(Grid, { container: true, item: true, xs: 12 },
                React.createElement(Grid, { container: true, alignItems: 'center' },
                    React.createElement(Grid, { style: { display: 'flex' }, alignItems: 'center' },
                        React.createElement(MatchText, { text: order.orderId || '', match: search, variant: 'subtitle1' }),
                        order.orderCustomId ? (React.createElement(Grid, { style: { display: 'flex', margin: theme.spacing(0, 1) } },
                            React.createElement(MatchText, { text: "" + order.orderCustomId || '', match: search, variant: 'subtitle2' }))) : null,
                        (order.templateName && (React.createElement(MatchText, { text: order.templateName || '', match: search, variant: 'caption' }))) ||
                            null),
                    React.createElement(TextOverflow, { variant: 'caption', style: { color: theme.palette.grey['500'], margin: theme.spacing(0, 1) } }, "" + [
                        order.sorting_totalWeight,
                        [dateViewFormat(order.sorting_pickupAt), dateViewFormat(order.sorting_deliveryAt)].join(' - '),
                    ]
                        .filter(function (e) { return e; })
                        .join(', ')),
                    (statusText && (React.createElement(TextOverflow, { variant: 'caption', style: { color: theme.palette.primary.light, marginRight: theme.spacing(1) } }, statusText))) ||
                        null,
                    (order.isTemplate && (React.createElement(TextOverflow, { variant: 'caption', style: { textDecoration: 'underline' } }, langForm.t('form_input_is_template')))) ||
                        null)),
            React.createElement(Grid, { container: true, item: true, xs: 8, alignItems: 'center' },
                React.createElement(Grid, { container: true, direction: 'column' },
                    React.createElement(Grid, { container: true }, order.transportations
                        .filter(function (e) { return e; })
                        .map(function (t, index) { return (React.createElement(Grid, { container: true, key: "transport-" + index }, t.routes.map(function (route) { return (React.createElement(Grid, { container: true, alignItems: 'center', style: { maxWidth: '100%' }, key: route._id },
                        route.origin && route.origin.address && route.origin.address.country && (React.createElement(FlagComponent, { style: { maxWidth: 12, maxHeight: 12 }, country: route.origin.address.country })),
                        route.origin && route.origin.address ? (React.createElement(MatchText, { text: [
                                route.origin.companyName || (route.origin.companyId && route.origin.companyId.name),
                                OrderLocation.create(route.origin).fullAddress,
                            ]
                                .filter(function (e) { return e; })
                                .join(', ') || '', parentStyle: { width: '100%', marginLeft: theme.spacing(1), maxWidth: 'calc(100% - 20px)' }, match: search, style: { maxWidth: '100%' }, variant: 'caption' })) : ('-'))); }))); })))),
            React.createElement(Grid, { container: true, item: true, xs: 4, justify: 'flex-end' },
                React.createElement(Grid, { container: true, direction: 'column' },
                    React.createElement(MatchText, { text: (order.manager && order.manager.company && order.manager.company.name) ||
                            (order.createdBy && order.createdBy.company && order.createdBy.company.name) ||
                            '', parentStyle: { justifyContent: 'flex-end' }, match: search, variant: 'subtitle1' }),
                    React.createElement(MatchText, { text: (order.manager && order.manager.fullName) || (order.createdBy && order.createdBy.fullName) || '', parentStyle: { justifyContent: 'flex-end' }, match: search, variant: 'caption' }))))));
    return React.createElement(SearchOrderLinkView, { order: order }, content);
};
export var SearchOrderLinkView = inject('store')(function (_a) {
    var _b;
    var _c;
    var children = _a.children, order = _a.order, currentCompany = _a.store.accountStore.currentCompany, props = __rest(_a, ["children", "order", "store"]);
    var link = "/orders/" + order._id;
    var localSearchId = props.isChatSearch || 1 ? order._id : order.orderId;
    if ((_c = currentCompany.settings) === null || _c === void 0 ? void 0 : _c.canViewAllOrders) {
        switch (order.status) {
            case OrderStatus.AcceptedQuote: {
                link = "/open-orders?search=" + order._id;
                break;
            }
            case OrderStatus.ApprovedQuote: {
                link = "/in-progress?search=" + order._id;
                break;
            }
            case OrderStatus.WaitingQuotes: {
                link = "/open-orders?search=" + order._id;
                break;
            }
            case OrderStatus.HasQuotes: {
                link = "/open-orders?search=" + order._id;
                break;
            }
            case OrderStatus.Draft: {
                link = "/drafts?search=" + order._id;
                if (order.isTemplate) {
                    link = "/templates?search=" + order._id;
                }
                break;
            }
            case OrderStatus.Finished: {
                link = "/archive?search=" + order._id;
                break;
            }
            default: {
                link = '';
            }
        }
    }
    else {
        switch (order.status) {
            case OrderStatus.AcceptedQuote: {
                link = "/open-orders?" + ((order.latestQuote && 0 && 'status=accepted&') || '') + "&search=" + localSearchId;
                break;
            }
            case OrderStatus.ApprovedQuote: {
                if (order.latestQuote && Object.values(OrderInProgressStatus).includes(order.latestQuote.inProgressStatus)) {
                    var status_1 = (_b = {},
                        _b[OrderInProgressStatus.AssignedUnLoaded] = OrderQuoteStatusState.Unloaded,
                        _b[OrderInProgressStatus.AssignedTransport] = OrderQuoteStatusState.Transport,
                        _b[OrderInProgressStatus.AssignedLoadingTime] = OrderQuoteStatusState.TimeSelected,
                        _b[OrderInProgressStatus.AssignedUnLoadingTime] = OrderQuoteStatusState.TimeSelected,
                        _b[OrderInProgressStatus.AssignedInTransit] = OrderQuoteStatusState.InTransit,
                        _b);
                    link = "/in-progress?" + ((0 && 'state=${status[order.latestQuote.inProgressStatus]}&') || '') + "search=" + localSearchId;
                }
                else {
                    link = "/in-progress?search=" + localSearchId;
                }
                break;
            }
            case OrderStatus.WaitingQuotes: {
                link = "/open-orders?" + ((0 && 'status=requested&') || '') + "search=" + (order._id || localSearchId);
                break;
            }
            case OrderStatus.HasQuotes: {
                link = "/open-orders?" + ((order.latestQuote && 0 && 'status=quoted&') || '') + "search=" + (order._id || localSearchId);
                break;
            }
            case OrderStatus.Draft: {
                link = "/drafts?search=" + (order._id || localSearchId);
                if (order.isTemplate) {
                    link = "/templates?search=" + (order._id || localSearchId);
                }
                break;
            }
            case OrderStatus.Finished: {
                link = "/archive?search=" + (order._id || localSearchId);
                break;
            }
            default: {
                link = '';
            }
        }
    }
    return link ? (React.createElement(Link, { style: __assign({ textDecoration: 'none', color: 'initial', display: 'flex', width: '100%' }, props.style), to: link }, children)) : (children);
});
